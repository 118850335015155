<style lang="scss">
.region-select {
  background-color: transparent;
  border-radius: 1em;
  max-width: 200px;
  text-transform: capitalize;

  .v-input__slot {
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }

  &.dark {
    .v-input__slot {
      background-color: $dark-blue-alt !important;

      i, .v-select__selection {
        color: white !important;
      }
    }
  }
}
</style>

<template>
<!--  <section class="d-flex justify-end">-->
  <section class="mx-6">
    <v-select
        class="region-select" :class="{'dark': darkMode}"
        :value="region" @input="setRegion($event)"
        :items="[{text: 'All Regions', value: ''}, ...regions]"
        solo
    ></v-select>
  </section>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    props: [''],
    components: {},
    data() {
      return {
        test: '',
      };
    },
    computed: {
      ...mapState({
        regions: state => state.radio.regions,
        region: state => state.radio.region,
        darkMode: state => state.darkMode,
      })
    },
    mounted() {

    },
    methods: {
      setRegion(e) {
        this.$store.dispatch('radio/changeRegion', e);
      }
    },
  };
</script>
