<style lang="scss">
$color_bar: $purple-light;
$color_high: $purple-dark;
$color_background: #2F2933;

.visualizer-wrapper{
  height: 130px;
  overflow: hidden;

  &.paused .visualizer{
    animation-play-state:paused;
    opacity: 0;
  }
}

@keyframes bar1 {
  0%,
  19.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%), 0 -90px 0 mix($color_high, $color_bar, 75%), 0 -120px 0 mix($color_high, $color_bar, 100%);
  }
  20%,
  39.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%), 0 -90px 0 mix($color_high, $color_bar, 75%);
  }
  40%,
  59.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
  }
  60%,
  79.9% {
    box-shadow: 0 -30px 0 $color_bar;
  }
  80%,
  100% {
    box-shadow: none;
  }
}
@keyframes bar2 {
  0%,
  19.9% {
    box-shadow: none;
  }
  20%,
  39.9% {
    box-shadow: 0 -30px 0 $color_bar;
  }
  40%,
  59.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
  }
  60%,
  79.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%), 0 -90px 0 mix($color_high, $color_bar, 75%), 0 -120px 0 mix($color_high, $color_bar, 100%);
  }
  80%,
  100% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%), 0 -90px 0 mix($color_high, $color_bar, 75%);
  }
}
@keyframes bar3 {
  0%,
  19.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
  }
  20%,
  39.9% {
    box-shadow: 0 -30px 0 $color_bar;
  }
  40%,
  59.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
  }
  60%,
  79.9% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%), 0 -90px 0 mix($color_high, $color_bar, 75%);
  }
  80%,
  100% {
    box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%), 0 -90px 0 mix($color_high, $color_bar, 75%), 0 -120px 0 mix($color_high, $color_bar, 100%);
  }
}

.visualizer{
  transform: translate(10px,100px);
  width: 15%;
  max-width: 100px;
  height: 20px;
  background: $color_bar;
  position: relative;
  box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 $color_bar, 0 -90px 0 $color_bar, 0 -120px 0 $color_bar;
  animation-name: bar1;
  animation-duration: 0.3s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-right: 15px;
  animation-delay: calc(0.35s * var(--index));
}

</style>

<template>
  <div class="visualizer-wrapper d-none d-sm-flex" :class="{'paused': paused}">
    <div v-for="num in amount" :key="num" class="visualizer"  :style="{'--index':  num}"></div>
  </div>
</template>

<script>
export default {
  props: {
    paused: Boolean,
    amount: Number
  },
  components: {},
  data() {
    return {
      test: '',
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>
