import radioService from '@/services/radioService';
export default {
  addFavourite(station, toggle = false){
    let favourites = this.getFavourites();

    if(!this.getFavouriteByName(station.name)) { // if favourite doesn't exist
      radioService.sendStat({station: station}, `Added station: ${station.name} to favourites`);
      favourites.push(station);
      this.setFavourites(favourites);
    } else if (toggle) {
      radioService.sendStat({station: station}, `Removed station: ${station.name} from favourites`);
      this.removeFavourite(station);
    }
  },
  removeFavourite(station){
    let favourites = this.getFavourites();

    favourites = favourites.filter((item) => {
      return item.name !== station.name;
    });

    this.setFavourites(favourites);
  },
  getFavourites(){
    let favourites = localStorage.getItem('favourites');

    if(favourites){
      favourites = JSON.parse(favourites);
    } else {
      favourites = [];
    }

    return favourites;
  },
  setFavourites(stations){
    localStorage.setItem('favourites',JSON.stringify(stations));
  },
  getFavouriteByName(name){
    let favourites = this.getFavourites();
    let selected = null;

    favourites.forEach((item) => {
      if(item.name === name){
        selected = item;
      }
    });

    return selected;
  }
};
