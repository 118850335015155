<style lang="scss">
.playing-banner{
  background-color: $purple-dark;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .logo-wrapper{
    //background-color: white;
    .favourite-icon{
      position: absolute;
      top: -1px;
      right: 5px;
      font-size: 20pt;
      color: white;

      i{
        text-shadow: 1px 1px 5px black;
      }

      &.active{
        color: $purple;
      }
    }
  }

  &__logo{
    width: 192px;
    height: 100%;
    border-radius: 20px;
    box-shadow: 5px 5px 15px #888, -1px -2px 10px #fff;
  }

  &__content{
    flex-grow: 1;
  }

  &__controls{
    width: 100%;
    max-width: 400px;

    .pause-play-btn{
      background-color: black;
      border-radius: 40px;
      font-size: 30pt;
      width: 40px;
      height: 40px;
    }

    .speaker-icon{
      width: 33px; // stops jumping between icon changes
      font-size: 22pt;
    }

    .v-slider__thumb{
      width: 20px;
      height: 20px;
    }

    .v-input{
      max-height: 33px;
    }

    .v-slider__track-container{
      height: 10px;
    }
  }


}
</style>

<template>
  <div class="playing-banner-wrapper">
    <section :style="{backgroundImage: 'url(' + getBackgroundUrl() + ')'}"
        class="playing-banner pa-8 d-flex flex-wrap justify-center">
      <div class="logo-wrapper p-relative">
        <img :src="getImgUrl(station.details.image_url)" alt="station logo" class="playing-banner__logo">
        <span class="favourite-icon"
              :class="{'active': isFavourite}"
              @click="$store.dispatch('favourites/toggleFavourite', station.details)"><i class="fas fa-heart"></i></span>
      </div>


      <div class="playing-banner__content ml-8 mt-4 mt-sm-0">
<!--        <visualizer :amount="6" :paused="playing.paused || playing._state === 'loading'"></visualizer>-->
        <visualizer :amount="6" :paused="true"></visualizer>

        <section class="playing-banner__controls">
          <p class="white--text font-weight-bold mb-0">Now playing {{station.details.name}}</p>

          <div class="d-flex align-center">
            <span v-if="!playing.paused" @click="$emit('pause', true)" class="pause-btn pause-play-btn d-flex mr-4"><i class="fas fa-pause-circle white--text"></i></span>
            <span v-if="playing.paused" @click="$emit('pause', false)" class="pause-btn pause-play-btn d-flex mr-4"><i class="fas fa-play-circle white--text"></i></span>
            <span v-if="!playing._muted" @click="$emit('mute', true)" class="speaker-icon white--text mr-2"><i class="fas fa-volume-up"></i></span>
            <span v-if="playing._muted" @click="$emit('mute', false)" class="speaker-icon white--text mr-2"><i class="fas fa-volume-mute"></i></span>
            <v-slider @change="$emit('update-volume', volume)"
                      color="white"
                      thumb-color="#9938B9"
                      v-model="volume" step="0.1" max="1"></v-slider>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Visualizer from './equalizers/Visualizer';
import radioService from '../services/radioService';
const lightImg = require(`../assets/radio-light.jpg`);
const darkImg = require(`../assets/radio-dark.jpg`);

export default {
  name: 'Playing-Banner',
  props: {},
  components: {
    Visualizer
  },
  computed: {
    ...mapState({
      favourites: state => state.favourites.favourites,
      darkMode: state => state.darkMode,
      station: state => state.radio.station,
      playing: state => state.radio.station.playing,
    }),
    isFavourite(){
      let favourite = false;

      this.favourites.forEach((item) => {
        if(item.name === this.station.details.name){
          favourite = true;
        }
      });

      return favourite;
    }
  },
  watch: {
    station: {
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    },
  },
  data() {
    return {
      volume: 1,
    };
  },
  mounted() {},
  methods: {
    getBackgroundUrl() {
      return !this.darkMode ? lightImg : darkImg;
    },
    getImgUrl: radioService.getImgUrl,
  },
};
</script>
