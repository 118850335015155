import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import axios from 'axios';

Vue.http = Vue.prototype.$http = axios;

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mdm',
    name: 'mdm',
    component: () => import('@/views/mdm/MdmSetup.vue'),
    meta: {
      headerHide: true
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
