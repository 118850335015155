<style lang="scss">
  .home{

  }
</style>
<template>
  <div class="home">
    <radio></radio>
  </div>
</template>

<script>
import Radio from '../components/radio.vue';

export default {
  name: 'Home',
  components: {
    Radio,
  }
}
</script>
