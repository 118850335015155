import Vue from 'vue';
import Vuex from 'vuex';
import FavouritesModule from './modules/FavouritesModule';
import RadioModule from '@/store/modules/RadioModule';
import themeService from '../services/themeService';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    darkMode: false,
    isAndroid: false,
    isElectron: false,
  },
  mutations: {
    SET_DARK_MODE(state, value){
      state.darkMode = value;
      themeService.setDarkTheme(value);
    },
    SET_IS_ANDROID(state, value) {
      state.isAndroid = value;
    },
    SET_IS_ELECTRON(state, value) {
      state.isElectron = value;
    },
  },
  getters: {
    GET_IS_ELECTRON({state}) {
      return state.isElectron;
    }
  },
  actions: {
    setIsAndroid({commit}) {
      const ua = navigator.userAgent.toLowerCase();
      commit('SET_IS_ANDROID', ua.indexOf("android") > -1);
    }
  },
  modules: {
    favourites: FavouritesModule,
    radio: RadioModule,
  }
});

export default store;
