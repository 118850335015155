import favouriteService from '../../services/favouriteService';
export default {
  namespaced: true,
  state: {
    favourites: []
  },
  getters: {},
  mutations: {
    SET_FAVOURITES_FROM_LOCAL(state){
      state.favourites = favouriteService.getFavourites();
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    toggleFavourite({ commit }, station) {
      station.playing = null;
      favouriteService.addFavourite(station, true);
      commit('SET_FAVOURITES_FROM_LOCAL', true);
    },
  }
};
