<style lang="scss">
.favourites-section{
  min-height: 300px;

  .swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 300px;
      height: 300px;
      position: relative;
      border-radius: 20px;
      box-shadow: 1px 1px 12px #fff, -1px -2px 10px #fff;

      .favourite-name-overlay{
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }

      .favourite-icon{
        position: absolute;
        top: -1px;
        right: 5px;
        font-size: 20pt;
        color: white;

        i{
          text-shadow: 1px 1px 5px black;
        }

        &.active{
          color: $purple-alt;
        }
      }
    }
  }

  .no-favourites{
    font-size: 18pt;
  }
}
</style>

<template>
  <section class="favourites-section theme-purple-background">
    <div class="swiper-container" v-if="favourites.length > 0">
      <div class="swiper-wrapper">
        <div @click="playFavourite(favourite, $event)" v-for="favourite in favourites" :key="favourite.name"
             class="swiper-slide" :style="{backgroundImage: 'url(' + getImgUrl(favourite.image_url) + ')'}">
          <span class="favourite-name-overlay white--text font-weight-bold">{{favourite.name}}</span>
          <span class="favourite-icon"
                :class="{'active': isFavourite(favourite)}"
                @click="$store.dispatch('favourites/toggleFavourite', favourite)"><i class="fas fa-heart"></i></span>
        </div>
      </div>
      <!-- Add Pagination -->
      <!--            <div class="swiper-pagination"></div>-->
    </div>
    <div class="no-favourites white--text font-weight-bold px-6 py-10" v-else>
      <p >You have no favourites :-(</p>
      <p >Touch the heart <span class="sad-icon white--text"><i class="fas fa-heart"></i></span>
        on any station to add some!</p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css'; // import Swiper styles
import radioService from '../services/radioService';

export default {
  props: {},
  components: {},
  computed: {
    ...mapState({
      favourites: state => state.favourites.favourites,
    })
  },
  watch: {
    favourites(){
      this.setUpSlides();
    }
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.setUpSlides();
  },
  methods: {
    getImgUrl: radioService.getImgUrl,
    setUpSlides(){
      setTimeout(() => {
        if (this.favourites.length > 0) {
          this.swiper = new Swiper('.swiper-container', {
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            },
            pagination: {
              el: '.swiper-pagination',
            },
          });

          // this.swiper.on('click', (swiper) => {
          //   console.log('swiper', swiper);
          //   if(swiper.clickedSlide) {
          //     console.log('swiper clicked slide', swiper);
          //     swiper.slideTo(swiper.clickedIndex);
          //   }
          // });

          setTimeout(() => {
            this.swiper.update();
          });
        }
      });
    },
    isFavourite(station){
      let favourite = false;

      this.favourites.forEach((item) => {
        if(item.name === station.name){
          favourite = true;
        }
      });

      return favourite;
    },
    playFavourite(favourite, event){
      let clickedImage = event.target.classList.contains('swiper-slide');

      if(clickedImage) {
        this.$emit('play', favourite);
      }
    }
  },
};
</script>
