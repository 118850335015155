export default {
  setDarkTheme(dark){
    let app = document.querySelector('#app');
    if(dark){
      app.classList.add('dark-theme');
      app.classList.remove('light-theme');
    } else {
      app.classList.add('light-theme');
      app.classList.remove('dark-theme');
    }
  },
  setFavourites(stations){
    localStorage.setItem('favourites',JSON.stringify(stations));
  },
};
