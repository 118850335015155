import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://radio-world-50-000-radios-stations.p.rapidapi.com/v1/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-rapidapi-host': 'radio-world-50-000-radios-stations.p.rapidapi.com',
    'x-rapidapi-key': 'a182a6f797mshb8a1c5f2dcb3392p1cda19jsne66081a9d212',
    'useQueryString': true

  }
});

export default {
  getStationsByName(name){
    return apiClient.get(`radios/searchByName?query=${name}`);
  },
  getImgUrl(url) {
    if(url.includes('https')){
      return url;
    }

    return require('../assets/logos/'+ url);
  },
  sendStat(meta, description, electron = false) {
    return new Promise(function (resolve, reject) {
      // eslint-disable-next-line no-undef
      const ua = navigator.userAgent.toLowerCase();
      const activity = {
        action: "Radio Log",
        description: description,
        meta: JSON.stringify(meta),
        target: "com.madepurple.purpleradio",
        app_id: 'com.madepurple.purpleradio',
      }

      if(ua.indexOf("android") > -1) {
        // eslint-disable-next-line no-undef
        Android.sendActivityForLog(JSON.stringify(activity));
        resolve('sent')
      }

      if (electron) {
        axios.post('electron-log', JSON.stringify(activity));
      }

      setTimeout(() => {
        reject('did not submit log');
      }, 10000);

      resolve('did not submit log');
    });
  },
};
