import radioService from '@/services/radioService';
import * as Stations from '@/components/stations.json';

export default {
  namespaced: true,
  state: {
    station: {  // currently playing channel
      playing: null,
      details: null
    },
    stations: Stations.stations,
    region: '',
    regions: ['uk', 'au']
  },
  getters: {},
  mutations: {
    SET_STATION(state, value){
      state.station = value;
    },
    SET_STATION_PLAYING(state, value) {
      state.station.playing = value;
    },
    SET_STATION_PLAYING_PAUSED(state, value) {
      state.station.playing.paused = value;
    },
    SET_STATION_DETAILS(state, value) {
      state.station.details = value;
    },
    SET_REGION(state, value) {
      state.region = value;
    },
    SET_STATIONS(state, value) {
      state.stations = value;
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    toggleFavourite({ commit }, station) {
      commit('SET_FAVOURITES_FROM_LOCAL', true);
    },
    // eslint-disable-next-line no-unused-vars
    sendLog({state, rootState}, log) {
      radioService.sendStat(log.meta || {}, log.description, rootState.isElectron);
    },
    changeRegion({commit, state}, region) {
      if(state.regions.includes(region)) {
        commit('SET_REGION', region);

        const Filtered = Stations.stations.filter(item => {
          return item.region === region;
        });

        commit('SET_STATIONS', Filtered.length > 0 ? Filtered : Stations.stations);
      }
    }
  }
};
