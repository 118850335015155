<style lang="scss">
.relax-overflow{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #1a1818;
    transform: translate(100%, -100%);
    transition: transform 0.2s ease;

    &.active{
        transform: translate(0);
    }
}
</style>

<template>
    <div class="relax-overflow">
        <span class="white--text d-inline-block pa-4 float-right" @click="$emit('close', true)">Back to radio</span>
        <span class="white--text d-inline-block pa-4 float-left" @click="showEqualizer = !showEqualizer">Toggle Equalizer</span>
        <equalizer v-if="showEqualizer"></equalizer>
    </div>
</template>

<script>
    import Equalizer from './equalizers/Equalizer';
  export default {
    props: [''],
    components: {
      Equalizer
    },
    data() {
      return {
        showEqualizer: true,
      };
    },
    mounted() {

    },
    methods: {

    },
  };
</script>
